<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <CompanyManagerPopup v-if="pop.isShowCManager" :company="pop.company" v-model:manager.sync="add.manager" v-model="add.bizcard" @close="pop.hideCManager"></CompanyManagerPopup>
    <SelectClassPopup v-if="pop.isShowClass" v-model:company.sync="pop.company" v-model:classname.sync="add.classnm" v-model:idx_class.sync="add.idx_class" @close="pop.hideClassList"></SelectClassPopup>
    <CarrotTitle title="고객사일지 작성"></CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-row">
            <colgroup>
              <col width="180">
              <col width="*">
            </colgroup>
            <tbody>
            <tr>
              <th><span class="txt-red">*</span> 일시</th>
              <td>
                <carrot-date-picker v-model="add.vdate" class="dp-inblock mr-5"></carrot-date-picker>
                <carrot-time-select v-model="add.vtime" class="ml-10"></carrot-time-select>
              </td>
            </tr>
            <tr>
              <th><span class="txt-red">*</span> 목적</th>
              <td>
                <label v-for="(irow, i) in add.rtype_list" :key="i"><input type="radio" v-model="add.reason" :value="irow.code"><span class="ml-5 mr-20"> {{ irow.name}}</span></label>
              </td>
            </tr>
            <tr>
              <th><span class="txt-red">*</span> 고객사</th>
              <td>
                <carrot-company v-model="add.company" class="w-400px" style="display:none"></carrot-company>
                {{add.company_name}}
              </td>
            </tr>
            <tr>
              <th><span class="txt-red">*</span>기타사항<br>(장소 등)</th>
              <td>
                <textarea v-model.trim="add.etc_memo" class="w-100per h-100px"></textarea>
              </td>
            </tr>
            <tr>
              <th><span class="txt-red">*</span> 업체담당자</th>
              <td>
                <input type="text" v-model="add.manager" class="w-400px float-left mr-5" readonly>
                <button @click="pop.showCManager" class="btn-default float-left h-30px">선택</button>
              </td>
            </tr>
            <tr>
              <th><span class="txt-red">*</span> 상담내용</th>
              <td>
                <textarea v-model.trim="add.contents" class="w-100per h-100px"></textarea>
              </td>
            </tr>
            <tr>
              <th><span class="txt-red">*</span> 수업 지속성</th>
              <td>
                <label v-for="(irow, i) in add.cp_list" :key="i"><input type="radio" v-model="add.class" :value="irow.code"><span class="ml-5 mr-20"><img :src="require('@/assets/img/db_relation_0' + (i+1) + '.png')" alt="ico" class="ml-5"> {{ irow.name }}</span></label>
              </td>
            </tr>
            <tr>
              <th><span class="txt-red">*</span> 관계 지속성</th>
              <td>
                <label v-for="(irow, i) in add.rp_list" :key="i"><input type="radio" v-model="add.relation" :value="irow.code"><span class="ml-5 mr-20"><img :src="require('@/assets/img/db_durability_0' + (i+1) + '.png')" alt="ico" class="ml-5"> {{ irow.name }}</span></label>
              </td>
            </tr>
            </tbody>
          </table>

          <h3 class="block-title mt-40 mb-20">외근수당</h3>
          <table class="table-row">
            <colgroup>
              <col width="180">
              <col width="*">
            </colgroup>
            <tbody>
            <tr>
              <th>목적</th>
              <td>
                <label v-for="(irow, i) in add.rtype_list" :key="i"><input type="radio" @click="add.priceCheck(irow.price)" v-model="add.reason" :value="irow.code"><span class="ml-5 mr-20"> {{ irow.name}}</span></label>
                <p class="color-1">* 주말근무는 전자결재 > 추가 수당 신청서 작성 후 기안해 주세요.</p>
              </td>
            </tr>
            <tr>
              <th>클래스</th>
              <td>
                <span v-if="add.classnm==''" class="float-left mr-10">클래스를 선택하세요.</span>
                <span v-else class="float-left mr-10">{{ add.classnm }}</span>
                <button @click="pop.showClassList" class="btn-default float-left h-30px">클래스 선택</button>
              </td>
            </tr>
            <tr v-if="add.reason=='기타'">
              <th>외근수당</th>
              <td>
                <input type="text" class="float-left mr-10" v-model="add.price">
              </td>
            </tr>
            <tr>
                <th><span class="txt-red">*</span> Self Driven</th>
                <td>
                    <label v-for="(irow, i) in add.dtype_list" :key="i">
                        <input type="radio" v-model="add.driven" :value="irow.code">
                        <span class="ml-5 mr-20"> {{ irow.name}}</span>
                    </label>
                </td>
            </tr>
            </tbody>
          </table>

          <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
          <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex";
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CompanyManagerPopup from '@/components/popup/customerManagement/CompanyManagerPopup.vue'
import SelectClassPopup from '@/components/popup/customerManagement/SelectClassPopup.vue'
import CarrotDatePicker from "@/components/common/CarrotDatePicker";
import CarrotTimeSelect from "@/components/common/CarrotTimeSelect";
import CarrotCompany from "@/components/common/CarrotCompany";
import Swal from "sweetalert2";


export default {
  layout:"customerManagement",
  components: {
    CompanyManagerPopup,
    SelectClassPopup,
    CarrotDatePicker,
    CarrotTimeSelect,
    CarrotCompany
  },
  setup() {

    const router = new useRouter();
    const route  = new useRoute();
    const store = new useStore();
    const toast = useToast();

    const pop = reactive({
      // Popup 노출 여부
      company : 0,
      company_name:"",
      manager : "",
      rtype_list : [],
      dtype_list : [],

      vdate    : "",
      vtime    : "",
      reason   : [],
      contents : "",
      etc_memo : "",

      isShowCManager : false,
      isShowClass    : false,

      idx_class:0,
      showCManager : () => {
        pop.company        = add.company;
        pop.isShowCManager = true;
      },
      hideCManager : () => {
        pop.company        = 0;
        pop.isShowCManager = false;
      },

      showClassList : () => {
        pop.company     = add.company;
        pop.idx_class     = add.idx_class;
        pop.isShowClass = true;
      },
      hideClassList : () => {
        pop.company     = 0;
        pop.isShowClass = false;
      }
    });

    const add = reactive({
      cp_list : [],
      rp_list : [],
      rt_list : [],

      idx:0,
      idx_schedule : 0,
      bizcard      : 0,
      manager      : "",
      contents     : "",
      class        : "",
      relation     : "",

      idx_class : 0,
      classnm   : "",
      price     : 0,
      driven    : "Self Driven 2.0",

      info : {},

      priceCheck : (price) =>{
        add.price=price;
      },
      doCancel : () => {
        router.go(-1);
      },


      doSubmit : () => {
        console.log( add.idx_class);

        console.log( pop.idx_class);
        if(add.contents){
          add.contents = add.contents.replace(/(?:\r\n|\r|\n)/g, '<br />')
          add.contents = add.contents.split('\n').join('<br />');
        }
        if(add.etc_memo){
          add.etc_memo = add.etc_memo.replace(/(?:\r\n|\r|\n)/g, '<br />')
          add.etc_memo = add.etc_memo.split('\n').join('<br />');
        }


        let params = {
          vdate    : add.vdate,
          vtime    : add.vtime,
          reason   : add.reason,
          company  : add.company,
          contents : add.contents,
          etc_memo : add.etc_memo,

          schedule : add.idx_schedule,
          bizcard  : add.bizcard,
          class    : add.class,
          relation : add.relation,
          rtype     : add.info.rtype,
          idx_class : add.idx_class,
          classname : add.classnm,
          price : add.price,
          driven : add.driven
        };

        if( !params.vdate || !params.vtime || !params.reason.length || !params.company || !params.contents || !params.etc_memo || !params.driven ){
          toast.error("필수항목을 입력해 주세요.");
          return;
        }
        axios.post("/rest/customermgr/addScheduleOutSideWork", params).then((res) => {
          if( res.data.err == 0 ){
            Swal.fire({
              title : "등록되었습니다."
            }).then(() => {
              let ss = sessionStorage.getItem("scheduleVisitWeekly");
              if( ss ){
                ss = JSON.parse(ss);
                let bbs = {
                  page : 1, sdate : "", edate : "", state : "", office : 0, team : 0
                };
                bbs.state  = ss.state?ss.state:"";
                bbs.office = ss.office>0?ss.office:0;
                bbs.team   = ss.team>0?ss.team:0;

                let nd = new Date(add.vdate);
                let td = nd.getDate() - nd.getDay();
                let fd = new Date(nd.setDate(td));
                let ld = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate()+6);
                bbs.sdate = [fd.getFullYear(), fd.getMonth()>8?fd.getMonth()+1:'0'+(fd.getMonth()+1), fd.getDate()>9?fd.getDate():'0'+fd.getDate()].join('-');
                bbs.edate = [ld.getFullYear(), ld.getMonth()>8?ld.getMonth()+1:'0'+(ld.getMonth()+1), ld.getDate()>9?ld.getDate():'0'+ld.getDate()].join('-');

                sessionStorage.setItem('scheduleVisitWeekly', JSON.stringify({
                  sdate  : bbs.sdate,
                  edate  : bbs.edate,
                  state  : bbs.state,
                  office : bbs.office,
                  team   : bbs.team
                }));
              }

              router.go(-1);

            })
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
      /*

      doSubmit : () => {
        let params = {
          schedule : add.idx_schedule,
          bizcard  : add.bizcard,
          contents : add.contents,
          class    : add.class,
          relation : add.relation,

          rtype     : add.info.rtype,
          idx_class : add.idx_class,
          classname : add.classnm,
          price     : add.info.price.replace(/[^0-9]/gi, '')
        };



        axios.post("/rest/customermgr/addOutsideWork", params).then((res) => {
          if( res.data.err == 0 ){
            router.push({
              name : "customerManagement-scheduleVisitView-idx",
              params : {
                idx : add.idx_schedule
              }
            });
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
      */

      doSearchRtype : () => {
        axios.get("/rest/customermgr/getRtypeList", { params:{} }).then((res) => {
          if( res.data.err == 0 ){
            add.rt_list = res.data.list;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        })
      },

      doSearchDtype : () => {
        axios.get("/rest/customermgr/getDtypeList", { params:{} }).then((res) => {
          if( res.data.err == 0 ){
            add.dtype_list = res.data.list;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        })
      },

      doSearchCP : () => {
        axios.get("/rest/customermgr/getCPtypeList", { params:{} }).then((res) => {
          if( res.data.err == 0 ){
            add.cp_list = res.data.list;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        })
      },

      doSearchRP : () => {
        axios.get("/rest/customermgr/getRPtypeList", { params:{} }).then((res) => {
          if( res.data.err == 0 ){
            add.rp_list = res.data.list;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        })
      },

      doSearch : () => {
        let params = {
          idx : add.idx_schedule
        };

        axios.get("/rest/customermgr/getScheduleInfo", { params : params }).then((res) => {
          if( res.data.err == 0 ){
            add.info = res.data;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
      doSearch2 : () => {
        axios.get("/rest/customermgr/getRtypeList", { params:{} }).then((res) => {
          if( res.data.err == 0 ){
            add.rtype_list = res.data.list;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        })
      },
      doSearchInfo : () => {
        let params = {
          idx : add.idx
        };

        axios.get("/rest/customermgr/getCustomerDBInfo", { params : params }).then((res) => {
          if( res.data.err == 0 ){
            add.company=res.data.idx;
            add.company_name=res.data.kname;
          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
    });

    onMounted(() => {
      // Mounted
      add.idx_schedule = route.params.idx?route.params.idx:0;

      if ( !add.idx_schedule ) {
        router.back(-1);
      }
      add.idx = route.params.idx?route.params.idx:0;
      add.doSearch();
      add.doSearch2();
      add.doSearchRtype();
      add.doSearchDtype();
      add.doSearchCP();
      add.doSearchRP();
      add.doSearchInfo();
      if(store.state.scheduleOutsideWork && store.state.scheduleOutsideWork != {}){
        add.reason = "인바운드 문의";
          //스토어의 scheduleOutsideWork정보를 가져와서 바인딩한다.
        if(store.state.scheduleOutsideWork.createdate && store.state.scheduleOutsideWork.createdate != ''){
            add.vdate = store.state.scheduleOutsideWork.createdate.substring(0,10);
            add.vtime = store.state.scheduleOutsideWork.createdate.substring(11,16);
        }
        if(store.state.scheduleOutsideWork.contents && store.state.scheduleOutsideWork.contents != ''){
            add.contents = store.state.scheduleOutsideWork.contents;
        }
        if(store.state.scheduleOutsideWork.idx_bizcard && store.state.scheduleOutsideWork.idx_bizcard != ''){
            add.bizcard = store.state.scheduleOutsideWork.idx_bizcard;
        }
        if(store.state.scheduleOutsideWork.manager && store.state.scheduleOutsideWork.manager != ''){
            add.manager = store.state.scheduleOutsideWork.manager;
        }
      }
      console.log("scheduleOutsideWork : ", store.state.scheduleOutsideWork);
      console.log("add : ", add);
      //store.commit("clearScheduleOutsideWork");
    });

    return {pop, add};
  }
}
</script>

<style lang="scss" scoped>
</style>